<template>
    <div>

        <div id="typeTrend" style="width:100%;height:100%"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
    props: {
        leakTypeTrend: {
            type: Object,
            default: function () {
                return {}
            }
        },
    },
    data() {
        return {

        }
    },
    watch: {
        leakTypeTrend: {
            handler(newV, oldV) {
                this.leakTypeTrend = newV;
                setTimeout(() => {
                    this.typeTrend();
                }, 600)
            },
            immediate: true, 
            deep: true, 
        },
        'leakTypeTrend.typeName': {
            handler(newVal, oldVal) {
                this.leakTypeTrend.typeName = newVal;
                setTimeout(() => {
                    this.typeTrend();
                }, 600)
            },
            deep: true,
            immediate: true,
        },
        'leakTypeTrend.versionDataInfo': {
            handler(newVal, oldVal) {
                this.leakTypeTrend.versionDataInfo = newVal;
                setTimeout(() => {
                    this.typeTrend();
                }, 600)
            },
            deep: true,
            immediate: true,
        },
        'leakTypeTrend.data': {
            handler(newVal, oldVal) {
                this.leakTypeTrend.data = newVal;
                setTimeout(() => {
                    this.typeTrend();
                }, 600)
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
    },
    mounted() {
        setTimeout(() => {
            this.typeTrend();
        }, 590)
    },
    methods: {
        typeTrend() {
            var chartDom = document.getElementById('typeTrend');

            var myChart = echarts.init(chartDom);

            var option;

            option = {
                color: ['#5470C6', '#91CC75', '#FAC858', '#EE6666', '#73C0DE', '#3BA272', '#FC8452', '#9A60B4', '#EA7CCC'],
                notMerge: true,
                tooltip: {
                    trigger: 'axis',
                    confine: true  //解决浮窗被截断问题
                },
                legend: {
                    data: this.leakTypeTrend.typeName,
                },
                grid: {
                    left: '7%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    axisLabel: {
                        // interval: 0,
                        // showMaxLabel: true,
                        rotate: 35
                    },
                    type: 'category',
                    nameLocation: "end",
                    show: this.leakTypeTrend.data.length > 0,
                    boundaryGap: false,
                    data: this.leakTypeTrend.versionDataInfo,
                },
                yAxis: {
                    minInterval: 1,//设置成1保证坐标轴分割刻度显示成整数
                    show: this.leakTypeTrend.data.length > 0,
                    type: 'value',
                    //设置网格线颜色
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: ['#eeeeee'],
                            width: 1,
                            type: 'solid'
                        }
                    },
                },
                series: this.leakTypeTrend.data,
                graphic: {
                    type: 'text',     // 类型：文本
                    left: 'center',
                    top: 'middle',
                    silent: true,     // 不响应事件
                    invisible: this.leakTypeTrend.data.length > 0,   // 有数据就隐藏
                    style: {
                        fill: '#909399',
                        fontWeight: 'bold',
                        text: this.$t('AppManage.ScanAnalysis.NoData'),
                        fontFamily: 'Microsoft YaHei',
                        fontSize: '24'
                    }
                }

            };

            option && myChart.setOption(option, true);
            window.addEventListener('resize', () => {
                myChart.resize()
            })
        }
    }
}
</script>

<style lang='scss' scoped>
</style>