<template>
  <div>
    <!-- 漏洞数量分布 -->
    <div id="counts" style="height:100%;width:100%;"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  props: {
    leakAmountDis: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {}
  },

  watch: {
    'leakAmountDis.name': {
      handler(newVal, oldVal) {
        this.leakAmountDis.name = newVal
        setTimeout(() => {
          this.counts()
        }, 600)
      },
      deep: true,
      immediate: true
    },
    'leakAmountDis.value': {
      handler(newVal, oldVal) {
        this.leakAmountDis.value = newVal
        setTimeout(() => {
          this.counts()
        }, 600)
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.counts()
    }, 600)
  },
  methods: {
    counts() {
      var chartDom = document.getElementById('counts')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        color: ['#378EFF'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          confine: true //解决浮窗被截断问题
        },
        xAxis: {
          type: 'category',
          show: this.leakAmountDis.name.length > 0,
          data: this.leakAmountDis.name,
          axisLabel: {
            interval: 0,
            rotate: 40
          }
        },
        grid: {
          top: '10%',
          left: '6%',
          right: '4%',
          bottom: '2%',
          containLabel: true
        },
        yAxis: {
          minInterval: 1, //设置成1保证坐标轴分割刻度显示成整数
          show: this.leakAmountDis.name.length > 0,
          //设置网格线颜色
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#eeeeee'],
              width: 1,
              type: 'solid'
            }
          }
        },
        series: [
          {
            type: 'bar',
            barWidth: 20, // 柱子宽度
            data: this.leakAmountDis.value
          }
        ],
        graphic: {
          type: 'text', // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true, // 不响应事件
          invisible: this.leakAmountDis.name.length > 0, // 有数据就隐藏
          style: {
            fill: '#909399',
            fontWeight: 'bold',
            text: this.$t('AppManage.ScanAnalysis.NoData'),
            fontFamily: 'Microsoft YaHei',
            fontSize: '24'
          }
        }
      }
      option && myChart.setOption(option)
      window.addEventListener('resize', () => {
        myChart.resize()
      })
    }
  }
}
</script>

<style lang='scss' scoped>
</style>