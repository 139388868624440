<template>
  <div class="scan">
    <!-- 检测分析 -->
    <el-row :gutter="4" class="mt15" style="display: flex">
      <el-col :span="3">
        <!-- 起始日期 -->
        <el-date-picker :clearable="false" size="small" style="width: 100%" v-model="formInline.startTime" type="date" :placeholder="$t('SecurityMonitor.WarningInfo.Placeholder')"
          value-format="yyyy-MM-dd" :picker-options="pickerOptions0" @change="changeDate">
        </el-date-picker>
      </el-col>
      <el-col :span="0.5">
        <span style="line-height: 30px; color: #dcdfe6">~</span>
      </el-col>
      <el-col :span="3">
        <el-date-picker :clearable="false" size="small" style="width: 100%" v-model="formInline.endTime" type="date" :placeholder="$t('SecurityMonitor.WarningInfo.Placeholder')"
          value-format="yyyy-MM-dd" :picker-options="pickerOptions1" @change="changeDate">
        </el-date-picker>
      </el-col>
      <el-col :span="3">
        <!-- 操作系统 -->
        <el-select style="width: 100%" v-model="formInline.version" :placeholder="$t('public.OperatingSystem')" size="small"
          @change="changeVersion">
          <el-option v-for="item in osVersion" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <!-- 应用名称 -->
        <el-select style="width: 100%" v-model="formInline.appName" filterable :placeholder="$t('public.ApplicationName')" size="small">
          <el-option v-for="item in appNameOptions" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-button size="small" type="primary" @click="onSubmit">{{$t('public.Inquire')}}</el-button>
        <el-button size="small" type="primary" @click="exportReport" style="margin-left: 2px">{{$t('AppManage.ScanAnalysis.ExportReport')}}</el-button>
      </el-col>
    </el-row>
    <div class="content">
      <div class="contentCharts">
        <!-- 图表 3个 -->
        <el-row :gutter="8" class="mt15 chartBox">
          <el-col :span="8" style="height:100%">
            <el-card class="box-card" style="height:100%">
              <!-- 漏洞级别占比 -->
              <div class="cardTitle">{{$t('AppManage.ScanAnalysis.LoopholeLevel')}}</div>
              <loopholeLevel class="echartsBox" :leakLevelPie="chartsData.leakLevelPie" />
            </el-card>
          </el-col>
          <el-col :span="8" style="height:100%">
            <el-card class="box-card" style="height:100%">
              <!-- 漏洞数量分布 -->
              <div class="cardTitle">{{$t('AppManage.ScanAnalysis.LoopholeCounts')}}</div>
              <loopholeCounts class="echartsBox" :leakAmountDis="leakAmountDis" />
            </el-card>
          </el-col>
          <el-col :span="8" style="height:100%">
            <el-card class="box-card" style="height:100%">
              <!-- 漏洞类型分布 -->
              <div class="cardTitle">{{$t('AppManage.ScanAnalysis.LoopholeType')}}</div>
              <loopholeType class="echartsBox" :leakTypeDis="leakTypeDis" />
            </el-card>
          </el-col>
        </el-row>
        <!-- 图表 折线图 2个  style="display: flex"-->
        <el-row :gutter="10" class=" chartBox" style="padding:9px 9px 0px">
          <el-col :span="12" style="height:97%">
            <el-card class="box-card" style="height:100%">
              <!-- 漏洞数量趋势 -->
              <div class="cardTitle">{{$t('AppManage.ScanAnalysis.LoopholeCountsTrend')}}</div>
              <loopholeCountsTrend class="echartsBox" :leakAmountDis="leakAmountDis" />
            </el-card>
          </el-col>
          <el-col :span="12" style="height:97%">
            <el-card class="box-card" style="height:100%">
              <!-- 漏洞类型趋势 -->
              <div class="cardTitle">{{$t('AppManage.ScanAnalysis.LoopholeTypeTrend')}}</div>
              <loopholeTypeTrend class="echartsBox" :leakTypeTrend="leakTypeTrend" />
            </el-card>
          </el-col>
        </el-row>
      </div>
      <!-- 右边漏洞top列表 -->
      <div class="contentList">
        <!-- 卡片 -->
        <el-row class="mt15" style="height:16%">
          <el-card class="box-card" style="height:100%">
            <el-col :span="8">
              <!-- 检测版本数 -->
              <div class="testCounts" style="text-align:center">{{$t('AppManage.ScanAnalysis.ScanVersionNum')}}</div>
              <div class="testNums" style="margin-top:4px;color:#177BE6;text-align:center" v-if="cardData.scanVersionNum == null">0
              </div>
              <div class="testNums" style="margin-top:4px;color:#177BE6;text-align:center">{{ cardData.scanVersionNum }}
              </div>
            </el-col>
            <el-col :span="8">
              <!-- 检测漏洞数 -->
              <div class="testCounts" style="text-align:center">{{$t('AppManage.ScanAnalysis.ScanLeakNum')}}</div>
              <div class="testNums" style="margin-top:4px;color:#E66918;text-align:center"
                v-if="cardData.scanLeakNum == null">0</div>
              <div class="testNums" style="margin-top:4px;color:#E66918;text-align:center">{{ cardData.scanLeakNum }}
              </div>
            </el-col>
            <el-col :span="8">
              <!-- 漏洞类型数 -->
              <div class="testCounts" style="text-align:center">{{$t('AppManage.ScanAnalysis.ScanTypeNum')}}</div>
              <div class="testNums" style="margin-top:4px;color:#6122E6;text-align:center"
                v-if="cardData.scanTypeNum == null">0</div>
              <div class="testNums" style="margin-top:4px;color:#6122E6;text-align:center">{{ cardData.scanTypeNum }}
              </div>
            </el-col>
          </el-card>
        </el-row>

        <el-row :gutter="12" class="mt15" style="height:42%">
          <el-col :span="24" style="height:100%">
            <el-card class="box-card1">
              <div class="rankingTitle">
                <!-- 漏洞检测排名 -->
                <span class="cardTitle">{{$t('AppManage.ScanAnalysis.LeakScanSort')}}</span>
              </div>
              <div style="padding-top:10px;height:95%">
                <loopdetdctRanking :leakScanSort="sortData.leakScanSort"></loopdetdctRanking>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="12" class="mt15" style="height:42%">
          <el-col :span="24" style="height:100%">
            <el-card class="box-card1">
              <div class="rankingTitle">
                <!-- 版本检测分数排名 -->
                <span class="cardTitle">{{$t('AppManage.ScanAnalysis.VersionGradeSort')}}</span>
              </div>
              <div style="padding-top:10px;height:95%">
                <versionScoreRanking :versionGradeSort="sortData.versionGradeSort"></versionScoreRanking>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import naviGation from '@/components/hearder/index'
import pagination from '@/components/pagination/page.vue'
import Eldialog from '@/components/elDialog'
import dialogInfo from '@/components/promptMessage/index'
import loopholeLevel from './components/loopholeLevel.vue'
import loopholeCounts from './components/loopholeCounts.vue'
import loopholeType from './components/loopholeType.vue'
import loopholeCountsTrend from './components/loopholeCountsTrend.vue'
import loopholeTypeTrend from './components/loopholeTypeTrend.vue'
import loopdetdctRanking from './components/loopdetdctRanking.vue'
import versionScoreRanking from './components/versionScoreRanking.vue'
export default {
  components: {
    naviGation,
    pagination,
    Eldialog,
    dialogInfo,
    loopholeLevel,
    loopholeCounts,
    loopholeType,
    loopholeCountsTrend,
    loopholeTypeTrend,
    loopdetdctRanking,
    versionScoreRanking
  },
  data() {
    return {
      groupId: '1',
      pickerOptions1: {},
      pickerOptions0: {},
      formInline: {
        startTime: '',
        endTime: '',
        level: '',
        reason: '',
        appName: '',
        location: [],
        input: '',
        version: '1'
      },
      cardData: { 
        scanLeakNum: '',
        scanTypeNum: '',
        scanVersionNum: ''
      },// 卡片
      sortData: {
        leakScanSort: [], //
        versionGradeSort: [] //版本检测分数排名
      },// 排名
      chartsData: {
        leakLevelPie: [] //漏洞级别占比
      },// 图表
      leakAmountDis: {
        name: [],
        value: []
      }, //漏洞数量分布与趋势
      leakTypeDis: {
        name: [],
        value: []
      }, //漏洞类型分布
      leakTypeTrend: {
        versionDataInfo: [], //版本名称所有
        data: [],
        typeName: [] //漏洞名称
      }, //漏洞类型趋势
      appNameOptions: [],
      osVersion: [
        { value: '1', label: 'Android' },
        { value: '2', label: 'iOS' }
      ],
      versionNum: [], //所有版本
    }
  },
  created() { },
  mounted() {
    const loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    setTimeout(() => {
      loading.close()
    }, 1000)
    this.getDataList()
    this.getAppName() //应用名称列表
    setTimeout(() => {
      this.getParserByCard() // 卡片内容
      this.getParserByChart() // 图表接口
      this.getParserBySortTable() //排名列表
    }, 500)
  },
  methods: {
    // 导出
    async exportReport() {
      let token2 = sessionStorage.getItem('Authorization')
        ? sessionStorage.getItem('Authorization')
        : 'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2NTIxNjkwOTMsInVzZXJJZCI6MSwiaWF0IjoxNjUyMTQ3NDkzfQ.IW5YpDyKCJcquEyVtHJFr-5S_9C2g3__cuk8wJK4YYSltBjQRuRwGmNVHaztoaLBEQDX0J-4PX2l1lIUeaRWgQ'
      let userString = decodeURIComponent(
        escape(window.atob(token2.split('.')[1]))
      )
      this.groupId = sessionStorage.getItem('groupId')
      console.log(this.groupId)
      const parma = {
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        appName: this.formInline.appName,
        osType: this.formInline.version,
        groupId: this.groupId
      }
      const res = await this.$axios.postExport(
        '/httpManageExport/appScanParser/exportReport',
        parma,
        true
      )
      const link = document.createElement('a')
      console.info(new Date())
      var fileName = 'exportScanParser_' + Date.parse(new Date()) + '.docx'
      let blob = new Blob([res.data], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      })
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', decodeURI(fileName))
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    // 查询
    onSubmit() {
      this.getAppName() //应用名称列表
      setTimeout(() => {
        this.getParserByCard() // 卡片内容
        this.getParserByChart() // 图表接口
        this.getParserBySortTable() //排名列表
      }, 500)
    },
    // 应用名称列表
    async getAppName() {
      const parma = {
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        osType: this.formInline.version
      }
      const res = await this.$axios.post(
        '/httpServe/appScanParser/getAppNames',
        parma,
        true
      )

      if (this.formInline.appName == '') {
        this.formInline.appName = res.data[0]
      }
      this.appNameOptions = res.data
    },
    // 切换操作系统
    changeVersion() {
      if (this.formInline.version == '1' || this.formInline.version == '2') {
        this.formInline.appName = ''
      }
      this.getAppName() //应用名称列表
    },
    // 卡片内容
    async getParserByCard() {
      const parma = {
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        appName: this.formInline.appName,
        osType: this.formInline.version
      }
      const res = await this.$axios.post(
        '/httpServe/appScanParser/getParserByCard',
        parma,
        true
      )
      this.cardData = res.data
    },
    // 排名列表
    async getParserBySortTable() {
      const parma = {
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        appName: this.formInline.appName,
        osType: this.formInline.version
      }
      const res = await this.$axios.post(
        '/httpServe/appScanParser/getParserBySortTable',
        parma,
        true
      )
      this.sortData.leakScanSort = res.data.leakScanSort.slice(0, 5)
      this.sortData.versionGradeSort = res.data.versionGradeSort.slice(0, 5)
    },
    // 图表接口
    async getParserByChart() {
      const parma = {
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        appName: this.formInline.appName,
        osType: this.formInline.version
      }
      const res = await this.$axios.post(
        '/httpServe/appScanParser/getParserByChart',
        parma,
        true
      )
      console.log(res, '图表接口')
      this.versionNum = res.data.leakTypeTrend.versionDataInfo //版本号  X轴
      // 漏洞级别占比
      var array = []
      array = res.data.leakLevelPie.map((v) => {
        if (v.name == '1') {
          v.name = this.$t('AppManage.ScanAnalysis.HighRisk');
          v.itemStyle = { color: '#FC2F3D' }
        }
        if (v.name == '2') {
          v.name = this.$t('AppManage.ScanAnalysis.MiddleRisk');
          v.itemStyle = { color: '#FF8D00' }
        }
        if (v.name == '3') {
          v.name = this.$t('AppManage.ScanAnalysis.LowRisk');
          v.itemStyle = { color: '#00C7CE' }
        }
        return { value: v.num, name: v.name, itemStyle: v.itemStyle }
      })
      this.chartsData.leakLevelPie = array
      //漏洞数量分布与趋势  this.versionNum
      const nameArr = []
      const dataArr = res.data.leakAmountDis
      const valueArr = Array(this.versionNum.length).fill(0)
      for (var i = 0; i < this.versionNum.length; i++) {
        for (var j = 0; j < dataArr.length; j++) {
          nameArr.push('v' + this.versionNum[i])
          if (this.versionNum[i] === dataArr[j].name) {
            valueArr[i] = dataArr[j].num
          }
        }
      }

      this.leakAmountDis.name = [...new Set(nameArr)]
      this.leakAmountDis.value = valueArr
      //漏洞类型分布
      var arr4 = new Array()
      var arr5 = new Array()
      res.data.leakTypeDis.forEach((item) => {
        if (item.name == '0') {
          item.name = this.$t('AppManage.ScanAnalysis.SafetyScanning');
        } else if (item.name == '1') {
          item.name = this.$t('AppManage.ScanAnalysis.ComponentVulnerability');
        } else if (item.name == '2') {
          item.name = this.$t('AppManage.ScanAnalysis.SourceCodeVulnerability');
        } else if (item.name == '3') {
          item.name = this.$t('AppManage.ScanAnalysis.GeneralVulnerability');
        } else if (item.name == '4') {
          item.name = this.$t('AppManage.ScanAnalysis.DataBreachRisk');
        } else if (item.name == '5') {
          item.name = this.$t('AppManage.ScanAnalysis.SensitiveBehavioralRisk');
        } else if (item.name == '6') {
          item.name = this.$t('AppManage.ScanAnalysis.SourceFileVulnerability');
        } else if (item.name == '7') {
          item.name = this.$t('AppManage.ScanAnalysis.IdentityAuthenticationRisk');
        } else if (item.name == '8') {
          item.name = this.$t('AppManage.ScanAnalysis.CommunicationTransmissionRisk');
        } else {
          item.name = this.$t('AppManage.ScanAnalysis.UnknownRisk');
        }
        arr4.push(item.name)
        arr5.push(item.num)
      })
      this.leakTypeDis.name = arr4
      this.leakTypeDis.value = arr5
      this.leakTypeTrend.data = []
      res.data.leakTypeTrend.leakDataInfo.forEach((item, index) => {
        if (item.typeName == '0') {
          item.typeName = this.$t('AppManage.ScanAnalysis.SafetyScanning');
        } else if (item.typeName == '1') {
          item.typeName = this.$t('AppManage.ScanAnalysis.ComponentVulnerability');
        } else if (item.typeName == '2') {
          item.typeName = this.$t('AppManage.ScanAnalysis.SourceCodeVulnerability');
        } else if (item.typeName == '3') {
          item.typeName = this.$t('AppManage.ScanAnalysis.GeneralVulnerability');
        } else if (item.typeName == '4') {
          item.typeName = this.$t('AppManage.ScanAnalysis.DataBreachRisk');
        } else if (item.typeName == '5') {
          item.typeName = this.$t('AppManage.ScanAnalysis.SensitiveBehavioralRisk');
        } else if (item.typeName == '6') {
          item.typeName = this.$t('AppManage.ScanAnalysis.SourceFileVulnerability');
        } else if (item.typeName == '7') {
          item.typeName = this.$t('AppManage.ScanAnalysis.IdentityAuthenticationRisk');
        } else if (item.typeName == '8') {
          item.typeName = this.$t('AppManage.ScanAnalysis.CommunicationTransmissionRisk');
        } else {
          item.typeName = this.$t('AppManage.ScanAnalysis.UnknownRisk');
        }
        this.leakTypeTrend.typeName.push(item.typeName)
        let emptyArrB = Array(this.versionNum.length).fill(0)
        const versionArr = [] //获取处理后的所有版本数据
        for (var i = 0; i < this.versionNum.length; i++) {
          const a = 'v' + this.versionNum[i]
          versionArr.push(a)
          this.leakTypeTrend.versionDataInfo = [...new Set(versionArr)]
          for (let j = 0; j < item.typeVersionTrend.length; j++) {
            if (this.versionNum[i] === item.typeVersionTrend[j].name) {
              emptyArrB[i] = item.typeVersionTrend[j].num
            }
          }
        }
        this.leakTypeTrend.data.push({
          name: item.typeName,
          version: this.leakTypeTrend.versionDataInfo, //处理后的版本数据
          data: emptyArrB,
          // stack: 'Total'+index,//用于数据堆叠，同个类目轴上，系列配置相同的stack，后一个系列的值会加在前一个系列之后
          type: 'line'
        })
      })
    },

    // 列表
    async getDataList() {
      //一个月前日期
      var getAnyMonth = function (symbol, n) {
        var symbol = symbol || '-'
        var nowDate = new Date()
        nowDate.setMonth(nowDate.getMonth() + n)
        var year = nowDate.getFullYear()
        var month = nowDate.getMonth() + 1
        var day = nowDate.getDate()
        day = day < 10 ? '0' + day : day
        month = month < 10 ? '0' + month : month
        return year + symbol + month + symbol + day
      } //当前日期
      var getDate = function () {
        var date = new Date()
        var year = date.getFullYear()
        var mon = date.getMonth() + 1
        var day = date.getDate()
        return (
          year +
          '-' +
          (mon < 10 ? '0' + mon : mon) +
          '-' +
          (day < 10 ? '0' + day : day)
        )
      }

      this.formInline.startTime = getAnyMonth('-', -1)
      this.formInline.endTime = getDate()
      this.changeDate()
    },
    changeDate() {
      let start = new Date(this.formInline.startTime).getTime()
      let end = new Date(this.formInline.endTime).getTime()
      this.pickerOptions0 = {
        disabledDate: (time) => {
          if (end != '') {
            return time.getTime() > Date.now() || time.getTime() > end
          }
          return time.getTime() > Date.now()
        }
      }
      this.pickerOptions1 = {
        disabledDate: (time) => {
          return (
            time.getTime() < start - 24 * 60 * 60 * 1000 ||
            time.getTime() > Date.now()
          )
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.scan {
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
}

.mt15 {
  padding: 9px;
}

.chartBox {
  height: 50%;
}

.content {
  width: 100%;
  height: calc(100vh - 150px);
  display: flex;
}

.echartsBox {
  height: 290px;
}
.contentCharts {
  width: 70%;
  height: 100%;
}

.contentList {
  width: 30%;
  height: 100%;
}

.box-card1 {
  height: 100%;
}

.cardTitle {
  font-size: 18px;
  margin-bottom: 20px;
  border-left: 3px solid #1955fd;
  padding-left: 3px;
  height: 10%;
}

.rankingTitle {
  font-size: 18px;
  height: 40px;
}

@media screen and (min-width: 2480px) and (max-width: 2560px) {
  .echartsBox {
  height: 430px;
}
  ::v-deep .el-input--small {
    font-size: 18px;
  }

  ::v-deep .el-button--small {
    font-size: 17px;
    padding: 11px 18px;
  }

  ::v-deep .cardTitle {
    font-size: 26px;
    margin-bottom: 30px;
    border-left: 6px solid #1955fd;
    padding-left: 3px;
    height: 10%;
  }

  ::v-deep .testCounts {
    font-size: 28px;
    margin-top: 8%;
  }

  //排名table
  ::v-deep .el-table .cell {
    line-height: 40px;
    font-size: 20px;
  }

  ::v-deep .testNums {
    font-size: 40px;
    margin-top: 8px;
  }

  ::v-deep .el-table__empty-block {
    width: 25%;
    font-size: 18px;
  }

  ::v-deep .el-input--small .el-input__inner {
    height: 42px;
    line-height: 42px;
  }

  ::v-deep .el-select-dropdown__item {
    font-size: 16px;
    height: 40px;
    line-height: 40px;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2040px) {
  .echartsBox {
  height: 290px;
}
  ::v-deep .el-input--small {
    font-size: 13px;
  }

  ::v-deep .el-button--small {
    font-size: 12px;
  }

  ::v-deep .cardTitle {
    font-size: 18px;
    margin-bottom: 20px;
    border-left: 3px solid #1955fd;
    padding-left: 3px;
    height: 10%;
  }

  ::v-deep .testCounts {
    font-size: 18px;
    margin-top: 4%;
  }

  ::v-deep .testNums {
    font-size: 26px;
    margin-top: 4px;
  }

  ::v-deep .el-table__empty-block {
    width: 25% !important;
  }
}

@media screen and (min-width: 1680px) and (max-width: 1919px) {
  .echartsBox {
  height: 280px;
  }
  ::v-deep .el-input--small {
    font-size: 11px;
  }

  ::v-deep .el-button--small {
    font-size: 10px;
  }

  ::v-deep .cardTitle {
    font-size: 16px;
    margin-bottom: 20px;
    border-left: 2px solid #1955fd;
    padding-left: 3px;
    height: 10%;
  }

  ::v-deep .testCounts {
    font-size: 16px;
    margin-top: 4%
  }

  ::v-deep .testNums {
    font-size: 24px;
  }

  ::v-deep .el-table__empty-block {
    width: 30% !important;
  }

  ::v-deep .el-input--small .el-input__inner {
    height: 30px;
    line-height: 30px;
  }

  ::v-deep .el-select-dropdown__item {
    font-size: 16px;
    height: 30px;
    line-height: 30px;
  }
}
</style>