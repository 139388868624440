<template>
  <div>
    <!-- 漏洞类型分布 -->
    <div id="type" style="height:100%;width:100%;"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  props: {
    leakTypeDis: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {}
  },
  watch: {
    'leakTypeDis.name': {
      handler(newVal, oldVal) {
        this.leakTypeDis.name = newVal
        setTimeout(() => {
          this.counts()
        }, 600)
      },
      deep: true,
      immediate: true
    },
    'leakTypeDis.value': {
      handler(newVal, oldVal) {
        this.leakTypeDis.value = newVal
        setTimeout(() => {
          this.counts()
        }, 600)
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.counts()
    }, 600)
  },
  methods: {
    counts() {
      var chartDom = document.getElementById('type')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        color: ['#24CDB6'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          confine: true //解决浮窗被截断问题
        },
        xAxis: {
          type: 'category',
          show: this.leakTypeDis.name.length > 0,
          data: this.leakTypeDis.name,
          axisLabel: {
            fontSize: 11, //字体大小
            interval: 0, //横轴信息全部显示，必须设置
            rotate: 40 //-30度角倾斜显示，根据页面布局实际可以自行调整
          }
        },
        grid: {
          top: '10%',
          left: '7%',
          right: '4%',
          bottom: '0%',
          containLabel: true
        },
        yAxis: {
          minInterval: 1, //设置成1保证坐标轴分割刻度显示成整数
          show: this.leakTypeDis.name.length > 0,
          //设置网格线颜色
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#eeeeee'],
              width: 1,
              type: 'solid'
            }
          }
        },
        series: [
          {
            data: this.leakTypeDis.value,
            type: 'bar',
            barWidth: 20 // 柱子宽度
          }
        ],
        graphic: {
          type: 'text', // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true, // 不响应事件
          invisible: this.leakTypeDis.name.length > 0, // 有数据就隐藏
          style: {
            fill: '#909399',
            fontWeight: 'bold',
            text: this.$t('AppManage.ScanAnalysis.NoData'),
            fontFamily: 'Microsoft YaHei',
            fontSize: '24'
          }
        }
      }
      option && myChart.setOption(option)
      window.addEventListener('resize', () => {
        myChart.resize()
      })
    }
  }
}
</script>

<style lang='scss' scoped>
</style>