import { render, staticRenderFns } from "./loopholeCounts.vue?vue&type=template&id=bc82c452&scoped=true&"
import script from "./loopholeCounts.vue?vue&type=script&lang=js&"
export * from "./loopholeCounts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc82c452",
  null
  
)

export default component.exports