<template>
  <div>
    <!-- <div>漏洞级别占比</div> -->
    <div style="height:100%;width:100%" id="main"></div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  props: {
    leakLevelPie: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {}
  },
  watch: {
    leakLevelPie: {
      handler(newVal, oldVal) {
        this.leakLevelPie = newVal
        setTimeout(() => {
          this.main()
        }, 600)
      },

      deep: true,
      immediate: true
    },
    'leakLevelPie.name': {
      handler(newVal, oldVal) {
        this.leakLevelPie.name = newVal
        setTimeout(() => {
          this.main()
        }, 600)
      },

      deep: true,
      immediate: true
    },
    'leakLevelPie.value': {
      handler(newVal, oldVal) {
        this.leakLevelPie.value = newVal
        setTimeout(() => {
          this.main()
        }, 600)
      },
      deep: true,
      immediate: true
    }
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.main()
    }, 600)
  },
  methods: {
    main() {
      var chartDom = document.getElementById('main')
      var myChart = echarts.init(chartDom)
      var option

      option = {
        title: {
          // text: '漏洞级别占比情况',
          textStyle: {
            fontSize: 12
          },
          left: 'center'
        },
        // 鼠标悬浮
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {d}%',
          confine: true //解决浮窗被截断问题
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          itemHeight: 6, // 修改icon图形高度
          itemWidth: 10, // 修改icon图形宽度
          itemGap: 5, // 修改间距
          data: this.leakLevelPie.name
        },
        series: [
          {
            type: 'pie',
            radius: ['30%', '50%'],
            center: ['50%', '60%'], //饼图位置调整
            label: {
              show: true,
              formatter: '{b}\n{d}%'
            },
            //视觉引导线
            labelLine: {
              show: true,
              length: 1 // 修改引导线第一段的长度
            },
            data: this.leakLevelPie,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ],
        graphic: {
          type: 'text', // 类型：文本
          left: 'center',
          top: 'middle',
          silent: true, // 不响应事件
          invisible: this.leakLevelPie.length > 0, // 有数据就隐藏
          style: {
            fill: '#909399',
            fontWeight: 'bold',
            text: this.$t('AppManage.ScanAnalysis.NoData'),
            fontFamily: 'Microsoft YaHei',
            fontSize: '24'
          }
        }
      }
      option && myChart.setOption(option)
      window.addEventListener('resize', () => {
        myChart.resize()
      })
    }
  }
}
</script>

<style lang='scss' scoped>
</style>