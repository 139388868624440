import { render, staticRenderFns } from "./loopholeCountsTrend.vue?vue&type=template&id=88f22622&scoped=true&"
import script from "./loopholeCountsTrend.vue?vue&type=script&lang=js&"
export * from "./loopholeCountsTrend.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88f22622",
  null
  
)

export default component.exports