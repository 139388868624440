<template>
    <div>
        <!-- 漏洞数量趋势 -->
        <div id="countsTrend" style="width:100%;height:100%"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
    props: {
        leakAmountDis: {
            type: Object,
            default: function () {
                return {}
            }
        },
    },
    data() {
        return {
        }
    },
    watch: {
        'leakAmountDis.name': {
            handler(newVal, oldVal) {
                this.leakAmountDis.name = newVal;
                setTimeout(() => {
                    this.countsTrend();
                }, 600)
            },
            deep: true,
            immediate: true,
        },
        'leakAmountDis.value': {
            handler(newVal, oldVal) {
                this.leakAmountDis.value = newVal;
                setTimeout(() => {
                    this.countsTrend();
                }, 600)
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
    },
    mounted() {
        setTimeout(() => {
            this.countsTrend();
        }, 600)
    },
    methods: {
        countsTrend() {
            var chartDom = document.getElementById('countsTrend');
            var myChart = echarts.init(chartDom);
            var option;

            option = {
                color: ['#FF7C00'],
                tooltip: {
                    trigger: 'axis',
                    show: true,
                    formatter: "{b}<br /><span style='display: inline-block; margin-right: 5px; border-radius: 10px; width: 9px; height: 9px; background-color: #FF7C00'></span>{c}",
                    confine: true  //解决浮窗被截断问题
                },
                legend: {
                    data: this.leakAmountDis.name
                },
                grid: {
                    left: '7%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    axisLabel: {
                        rotate: 35
                    },
                    type: 'category',
                    show: this.leakAmountDis.name.length > 0,
                    boundaryGap: false,
                    data: this.leakAmountDis.name,
                },
                yAxis: {
                    minInterval: 1,//设置成1保证坐标轴分割刻度显示成整数
                    show: this.leakAmountDis.name.length > 0,
                    //设置网格线颜色
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: ['#eeeeee'],
                            width: 1,
                            type: 'solid'
                        }
                    },
                },
                series: [
                    {
                        name: this.leakAmountDis.name,
                        data: this.leakAmountDis.value,
                        type: 'line',
                    }
                ],
                graphic: {
                    type: 'text',     // 类型：文本
                    left: 'center',
                    top: 'middle',
                    silent: true,     // 不响应事件
                    invisible: this.leakAmountDis.name.length > 0,   // 有数据就隐藏
                    style: {
                        fill: '#909399',
                        fontWeight: 'bold',
                        text: this.$t('AppManage.ScanAnalysis.NoData'),
                        fontFamily: 'Microsoft YaHei',
                        fontSize: '24'
                    }
                }
            };

            option && myChart.setOption(option);
            window.addEventListener('resize', () => {
                myChart.resize()
            })
        }
    }
}
</script>

<style lang='scss' scoped>
</style>