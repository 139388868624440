<template>
  <div>
    <!-- 版本检测分数排名 -->
    <el-table :data="versionGradeSort" stripe fit style="width: 100%;height:100%" :row-style="{ height: '36px' }"
      :cell-style="{ padding: '3px 0px' }" :header-row-style="{ height: '36px' }"
      :header-cell-style="{ background: '#f7f8fa', color: '#606266', padding: '0px' }"
      :default-sort="{ prop: 'CREATE_TIME', order: 'descending' }" tooltip-effect="dark" ref="multipleTable">
      <el-table-column min-width="45%" type="index" align="center" :show-overflow-tooltip="true" width="90">
        <template slot-scope="scope">
          <span v-if="scope.$index + 1 == 1" class="no1">TOP{{ scope.$index + 1 }}</span>
          <span v-else-if="scope.$index + 1 == 2" class="no2">TOP{{ scope.$index + 1 }} </span>
          <span v-else-if="scope.$index + 1 == 3" class="no3">TOP{{ scope.$index + 1 }} </span>
          <span v-else-if="scope.$index + 1 == 4" class="no4">TOP{{ scope.$index + 1 }} </span>
          <span v-else class="no4">TOP{{ scope.$index + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column prop="versionNumber" min-width="45%" :show-overflow-tooltip="true" :label="$t('AppManage.ScanAnalysis.VersionNumber')">
      </el-table-column>
      <el-table-column prop="scanGrade" min-width="45%" :show-overflow-tooltip="true" :label="$t('AppManage.ScanAnalysis.ScanGrade')">
      </el-table-column>
      <el-table-column prop="scanDate" min-width="45%" :show-overflow-tooltip="true" :label="$t('AppManage.ScanAnalysis.ScanDate')">
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    versionGradeSort: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      tableData: []
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang='scss' scoped>
.no1 {
  color: #f54b48;
  font-weight: 700;
}

.no2 {
  color: #ffae31;
  font-weight: 700;
}

.no3 {
  color: #41d99b;
  font-weight: 700;
}

.no4 {
  color: #414a60;
  font-weight: 700;
}

::v-deep .el-table__empty-block {
  width: 25% !important;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>