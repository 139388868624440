<template>
  <div>
    <!-- 漏洞检测排名 -->
    <el-table :data="leakScanSort" stripe fit style="width: 100%;height:100%" :row-style="{ height: '36px' }"
      :cell-style="{ padding: '3px 0px' }" :header-row-style="{ height: '36px' }"
      :header-cell-style="{ background: '#f7f8fa', color: '#606266', padding: '0px' }"
      :default-sort="{ prop: 'CREATE_TIME', order: 'descending' }" tooltip-effect="dark" ref="multipleTable">
      <el-table-column min-width="45%" type="index" align="center" :show-overflow-tooltip="true" width="90">
        <template slot-scope="scope">
          <span v-if="scope.$index + 1 == 1" class="no1">TOP{{ scope.$index + 1 }}</span>
          <span v-else-if="scope.$index + 1 == 2" class="no2">TOP{{ scope.$index + 1 }} </span>
          <span v-else-if="scope.$index + 1 == 3" class="no3">TOP{{ scope.$index + 1 }} </span>
          <span v-else-if="scope.$index + 1 == 4" class="no4">TOP{{ scope.$index + 1 }} </span>
          <span v-else class="no4">TOP{{ scope.$index + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column min-width="45%" prop="leakName" :show-overflow-tooltip="true" :label="$t('AppManage.ScanAnalysis.LeakName')">
      </el-table-column>
      <el-table-column min-width="45%" prop="leakType" :show-overflow-tooltip="true" :label="$t('AppManage.ScanAnalysis.LeakType')">
        <template v-slot="{ row }">
          <!-- 安全扫描 -->
          <template v-if="row.leakType == '0'">{{$t('AppManage.ScanAnalysis.SafetyScanning')}}</template>
          <!-- 组件漏洞 -->
          <template v-if="row.leakType == '1'">{{$t('AppManage.ScanAnalysis.ComponentVulnerability')}}</template>
          <!-- 源码漏洞 -->
          <template v-if="row.leakType == '2'">{{$t('AppManage.ScanAnalysis.SourceCodeVulnerability')}}</template>
          <!-- 通用漏洞 -->
          <template v-if="row.leakType == '3'">{{$t('AppManage.ScanAnalysis.GeneralVulnerability')}}</template>
          <!-- 数据泄露风险 -->
          <template v-if="row.leakType == '4'">{{$t('AppManage.ScanAnalysis.DataBreachRisk')}}</template>
          <!-- 敏感行为风险 -->
          <template v-if="row.leakType == '5'">{{$t('AppManage.ScanAnalysis.SensitiveBehavioralRisk')}}</template>
          <!-- 源文件漏洞 -->
          <template v-if="row.leakType == '6'">{{$t('AppManage.ScanAnalysis.SourceFileVulnerability')}}</template>
          <!-- 身份认证风险 -->
          <template v-if="row.leakType == '7'">{{$t('AppManage.ScanAnalysis.IdentityAuthenticationRisk')}}</template>
          <!-- 通信传输风险 -->
          <template v-if="row.leakType == '8'">{{$t('AppManage.ScanAnalysis.CommunicationTransmissionRisk')}}</template>
        </template>
      </el-table-column>
      <el-table-column min-width="45%" prop="scanNum" :show-overflow-tooltip="true" :label="$t('AppManage.ScanAnalysis.ScanNum')">
        <template v-slot="{ row }">
          <template>
            {{ row.scanNum }} 次
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    leakScanSort: {
      type: Array,
      default: function () {
        return []
      }
    },
  },

  data() {
    return {
      topNum: [],
      tableData: []
    }
  },
  created() {

  },
  mounted() {
  },
  methods: {

  }
}
</script>

<style lang='scss' scoped>
.no1 {
  color: #f54b48;
  font-weight: 700;
}

.no2 {
  color: #ffae31;
  font-weight: 700;
}

.no3 {
  color: #41d99b;
  font-weight: 700;
}

.no4 {
  color: #414a60;
  font-weight: 700;
}
::v-deep .el-table__empty-block {
    width: 25% !important;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>